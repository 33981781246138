<template>
  <div v-if="reservation">
    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.ReservationToDos.Subtitle_ToDos')
      }}</template>
    </ui-section-header>
    <!-- <div class="tabs">
      <ul>
        <li
          @click="selectedTabIndex = 0"
          :class="{ 'is-active': 0 === selectedTabIndex }"
        >
          <a
            >{{
              $t(
                'Components.Reservation.ReservationToDos.List_ToDosOfBooking'
              )
            }}
            #{{ reservation.Id }}</a
          >
        </li>
      </ul>
    </div> -->
    <transition name="fade" mode="out-in">
      <todos
        v-if="selectedTabIndex === 0"
        key="bookingToDos"
        :typeId="'Reservation'"
        :header="'ToDos of #' + reservation.Id"
        :itemId="reservation.Id"
        @todoUpdated="todoUpdated"
        @todoCreated="todoCreated"
        @todoDeleted="todoDeleted"
        :workEnvironment="'Manage'"
        :environmentId="reservation.LocationId"
      ></todos>

      <todos
        v-if="reservation.CompanyId > 0 && selectedTabIndex === 1"
        key="companyToDos"
        :header="'ToDos of ' + reservation.CompanyName"
        :typeId="'Company'"
        :itemId="reservation.CompanyId"
        :workEnvironment="'Manage'"
        :environmentId="reservation.LocationId"
      ></todos>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const ToDos = () => import('@/components/ToDos/ToDos')

export default {
  name: 'reservation-todos',

  components: {
    todos: ToDos,
  },

  data() {
    return {
      selectedTabIndex: 0,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    todoUpdated(todo) {
      let todoIndex = this.reservation.ToDos.findIndex((t) => t.Id === todo.Id)
      if (todoIndex > -1) {
        this.reservation.ToDos.splice(todoIndex, 1)
      } else {
        this.reservation.ToDos.push(todo)
      }
    },

    todoCreated(newToDo) {
      this.reservation.ToDos.push(newToDo)
    },

    todoDeleted(todoToDelete) {
      let self = this
      let todoIndex = self.reservation.ToDos.findIndex(
        (t) => t.Id === todoToDelete.Id
      )
      if (todoIndex > -1) {
        self.reservation.ToDos.splice(todoIndex, 1)
      }
    },
  },
}
</script>
